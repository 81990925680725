<template>
    <phi-page class="phi-transport-routes" :loading="app.api.isLoading">
        <div slot="toolbar" class="toolbar">
            <mu-icon-button @click="$router.go(-1)" icon="arrow_back" />
            <h1>{{ $t("launcher.transport") }}</h1>
            <select v-model="currentYear" class="year-picker">
                <option v-for="year in years" :key="year.id" :value="year">{{ year.name }}</option>
            </select>
        </div>

        <section v-for="shift in shifts" :key="shift.id" class="transport-shift">
            <h2>{{ shift.name }}</h2>
            <div class="routes phi-card">
                <router-link
                    class="phi-media"
                    v-for="route in shift.route"
                    :key="route.id"
                    :to="{name: 'transport-route-log', params: {routeId: route.id}}"
                >{{ route.name }}</router-link>
            </div>
        </section>

    </phi-page>
</template>

<script>
import app from '../../store/app.js';

export default {
    name: "phi-transport-routes",
    data() {
        return {
            app,
            years: null,
            currentYear: null,
            shifts: null
        }
    },

    methods: {
        loadYears() {
            app.api.get("/v3/years")
                .then(response => {
                    this.years = response;

                    for (let i in this.years) {
                        if (this.years[i].current == "1") {
                            this.currentYear = this.years[i];
                            break;
                        }
                    }
                });
        },

        loadShifts(year) {
            app.api.get(`/v3/transport/years/${year.id}/routes`, {monitor: app.user.id})
                .then(response => this.shifts = response);
        },
    },

    watch: {
        currentYear(newValue) {
            if (!newValue) {
                return;
            }

            this.loadShifts(newValue);
        }
    },

    mounted() {
        this.loadYears();
    }
}
</script>

<style lang="scss">
.phi-transport-routes {

    .transport-shift {
        margin-bottom: 24px;

        & > h2 {
            font-size: 1em;
            opacity: .6;
            padding: 3px;
        }
    }

    .year-picker {
        font-size: inherit;
        color: inherit;
        border: 0;
        background: transparent;
    }
}
</style>
